import PropTypes from 'prop-types';
import { useFormContext, useFieldArray } from 'react-hook-form';
// @mui
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { MenuItem, Typography } from '@mui/material';
// components
import Iconify from 'src/components/iconify';
import { RHFCheckbox, RHFSelect, RHFTextField } from 'src/components/hook-form';
import ImageUpload from './image_upload';


// ----------------------------------------------------------------------

const vehicleTypeList = [
    "CAR",
    " VAN",
];

export default function Vehicle({ isView, vehicleData }) {

    const { control, setValue } = useFormContext();


    const { fields, append, remove } = useFieldArray({
        control,
        name: 'vehicles',
    });


    const handleAdd = () => {
        append({
            vehicle_number: '',
            front_img: '',
            rear_img: '',
            back_img: '',
            no_of_seats: '',
            vehicle_type: vehicleTypeList[0],
            owner: false,
            vehicle_model: {
                model: vehicleData[0].model,
                year: vehicleData[0].year,
                brand: {
                    name: vehicleData[0].brand,
                    id: ''
                },
                vehicleType: null,
                id: vehicleData[0].id
            }
        });
    };

    const handleRemove = (index) => {
        remove(index);
    };


    return (
        <>
            <Stack divider={<Divider flexItem sx={{ borderStyle: 'dashed' }} />} spacing={3}>
                {fields.map((item, index) => (
                    <Stack key={item.id} spacing={1.5} >
                        <RHFSelect
                            disabled={!!isView}
                            fullWidth
                            name={`vehicles[${index}].vehicle_type`}
                            label="Vehicle Type"
                            InputLabelProps={{ shrink: true }}
                            PaperPropsSx={{ textTransform: 'capitalize' }}
                        >
                            {vehicleTypeList.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </RHFSelect>
                        <RHFTextField disabled={!!isView} name={`vehicles[${index}].vehicle_number`} label="Vehicle Number" />
                        <RHFTextField disabled={!!isView} name={`vehicles[${index}].no_of_seats`} label="Seats" />
                        <RHFSelect
                            disabled={!!isView}
                            fullWidth
                            name={`vehicles[${index}].vehicle_model.id`}
                            label="Vehicle Model"
                            InputLabelProps={{ shrink: true }}
                            PaperPropsSx={{ textTransform: 'capitalize' }}
                            onChange={(e) => {
                                const selectedModelId = e.target.value;
                                const selectedModel = vehicleData.find((option) => option.id === selectedModelId);
                                setValue(`vehicles[${index}].vehicle_model.model`, selectedModel.model);
                                setValue(`vehicles[${index}].vehicle_model.year`, selectedModel.year);
                                setValue(`vehicles[${index}].vehicle_model.brand.name`, selectedModel.brand.name);
                                setValue(`vehicles[${index}].vehicle_model.id`, selectedModel.id);
                            }}
                        >
                            {vehicleData.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {`${option.model} ${option.year}`}
                                </MenuItem>
                            ))}
                        </RHFSelect>
                        <RHFTextField disabled={!!isView} name={`vehicles[${index}].vehicle_model.model`} label="Model" />
                        <RHFTextField disabled={!!isView} name={`vehicles[${index}].vehicle_model.year`} label="Year" />
                        <RHFTextField disabled={!!isView} name={`vehicles[${index}].vehicle_model.brand.name`} label="Brand" />
                        <RHFCheckbox name={`vehicles[${index}].owner`} label="is Owner" disabled={!!isView}/>
                        <Stack spacing={1.5}>
                            <Typography variant="subtitle2">Front</Typography>
                            <ImageUpload name={`vehicles[${index}].front_img`} type='VEHICLE_IMG' isView={isView}/>
                        </Stack>
                        <Stack spacing={1.5}>
                            <Typography variant="subtitle2">Back</Typography>
                            <ImageUpload name={`vehicles[${index}].back_img`} type='VEHICLE_IMG' isView={isView}/>
                        </Stack>

                        <Stack spacing={1.5}>
                            <Typography variant="subtitle2">Inside</Typography>
                            <ImageUpload name={`vehicles[${index}].rear_img`} type='VEHICLE_IMG' isView={isView}/>
                        </Stack>

                        {
                            fields.length > 1 && !isView && (
                                <Button
                                    size="small"
                                    color="error"
                                    startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
                                    sx={{ flex: 1 }}
                                    onClick={() => handleRemove(index)}
                                >
                                    Remove
                                </Button>
                            )
                        }
                    </Stack>
                ))}
            </Stack >
            {!isView && (
                <>
                    <Divider sx={{ my: 1, borderStyle: 'dashed' }} />
                    <Stack spacing={1} >
                        <Button
                            size="small"
                            color="primary"
                            startIcon={<Iconify icon="mingcute:add-line" />}
                            sx={{ flex: 1 }}
                            onClick={handleAdd}
                        >
                            Add Vehicle
                        </Button>
                    </Stack>
                </>
            )
            }
        </>
    );
}

Vehicle.propTypes = {
    isView: PropTypes.bool,
    vehicleData: PropTypes.any,
};