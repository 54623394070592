import { useCallback, useEffect, useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// components
import Iconify from 'src/components/iconify';
import { useAuthContext } from 'src/auth/hooks';

// ----------------------------------------------------------------------

const icon = (name) => (
  <Iconify icon={name} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  driverReview: icon('ph:ranking-fill'),
  places: icon('ic:baseline-place'),
  affordable: icon('carbon:plan'),
  inquiry: icon('solar:document-bold'),
  tour: icon('material-symbols:tour'),
  payment: icon('streamline:payment-10-solid'),
  user: icon('ic:baseline-people'),
  partners: icon('material-symbols:partner-exchange'),
  profile: icon('icomoon-free:profile'),
  availability: icon('material-symbols:event-available')
};

// ----------------------------------------------------------------------

export function useNavData() {
  const { availableAccess } = useAuthContext();

  const getNavItem = useCallback(() => {
    const options = [
      {
        title: 'Driver Rankings',
        path: paths.dashboard.driverReview.root,
        icon: ICONS.driverReview,
        children: [
          { title: 'Leaderboard', path: paths.dashboard.driverReview.root },
          { title: 'Points', path: paths.dashboard.driverReview.review },
          { title: 'Criteria', path: paths.dashboard.driverReview.criteria },
        ],
      },
      {
        title: 'Driver Availabilities',
        path: paths.dashboard.driverAvailability.root,
        icon: ICONS.availability,
      },
      {
        title: 'Places',
        path: paths.dashboard.place.root,
        icon: ICONS.places,
      },
      {
        title: 'Affordables',
        path: paths.dashboard.affordable.root,
        icon: ICONS.affordable,
        children: [
          { title: 'Tour Packages', path: paths.dashboard.affordable.root },
          { 
            title: 'Tour Deals', 
            path: paths.dashboard.affordable.tourDeals,
            children: [
              { title: 'Tour Deal Types', path: paths.dashboard.affordable.tourDealsType },
              { title: 'Tour Deals', path: paths.dashboard.affordable.tourDeals },
            ]
          },
          { title: 'Tour Plan', path: paths.dashboard.affordable.tourPlan },
        ],
      },
      {
        title: 'Inquiries',
        path: paths.dashboard.inquiry.root,
        icon: ICONS.inquiry,
        children: [
          { title: 'New Inquiry', path: paths.dashboard.inquiry.createInquiry },
          { title: 'Pending Inquiry', path: paths.dashboard.inquiry.pendingInquiry },
          { title: 'Rejected Inquiry', path: paths.dashboard.inquiry.rejectedInquiry },
        ],
      },
      {
        title: 'Tours',
        path: paths.dashboard.tour.root,
        icon: ICONS.tour,
        children: [
          { title: 'Available Tours', path: paths.dashboard.tour.availableTours },
          { title: 'Accepted Tours', path: paths.dashboard.tour.acceptedTours },
          { title: 'Assigned Tours', path: paths.dashboard.tour.assignedTours },
          { title: 'Ongoing Tours', path: paths.dashboard.tour.ongoingTours },
          { title: 'Closed Tours', path: paths.dashboard.tour.closedTours }
        ],
      },
      {
        title: 'Payments',
        path: paths.dashboard.payment.root,
        icon: ICONS.payment,
        children: [
          { title: 'Driver Guide', path: paths.dashboard.payment.root },
          // { title: 'Payment Collector', path: paths.dashboard.payment.paymentCollector },
        ],
      },
      {
        title: 'Users',
        path: paths.dashboard.user.root,
        icon: ICONS.user,
        children: [
          { title: 'Staff', path: paths.dashboard.user.root },
          { title: 'Quick Driver Guide', path: paths.dashboard.user.driverGuide },
          { 
            title: 'Detailed Driver Guide', 
            path: paths.dashboard.user.createDetailedDriver,
            children: [
              { title: 'New Driver', path: paths.dashboard.user.createDetailedDriver },
              { title: 'Approved Drivers', path: paths.dashboard.user.detailedApprovedDriverGuide },
              { title: 'Pending Drivers', path: paths.dashboard.user.detailedPendingDriverGuide },
            ]
          },
          { title: 'Consultant', path: paths.dashboard.user.consultant },
          { title: 'Payment Collector', path: paths.dashboard.user.paymentCollector },
        ],
      },
      {
        title: 'Partners',
        path: paths.dashboard.partners.root,
        icon: ICONS.partners,
        children: [
          { title: 'Partner Type', path: paths.dashboard.partners.root },
          { title: 'Partner', path: paths.dashboard.partners.partner },
        ],
      },
      {
        title: 'Profile',
        path: paths.dashboard.profile.root,
        icon: ICONS.profile,
      },
    ];

    const array = [];

    for(let i = 0; i < availableAccess.length; i+=1){
      const obj = options[i];
      
      if(availableAccess[i].sub_access.length === 1){
        if(availableAccess[i].sub_access[0]){
          array.push(obj);
        }
      }
      else{
        const subOptions = [];

        for(let j = 0; j < availableAccess[i].sub_access.length; j += 1){
          if(availableAccess[i].sub_access[j]){
            subOptions.push(obj.children[j]);
          }
        }

        if(subOptions.length > 0){
          obj.children = [...subOptions];
          array.push(obj);
        }
      }
    }

    return array;
  }, [availableAccess]);

  const data = useMemo(
    () => [
      // MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: 'Sri lanka Driver Guide',
        items: getNavItem()
      },
    ],
    [getNavItem]
  );

  return data;
}