import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useMemo, useEffect, useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
// routes
import { useSnackbar } from 'src/components/snackbar';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// components
import FormProvider, {
    RHFTextField,
    RHFUploadAvatar,
} from 'src/components/hook-form';
import { fData } from 'src/utils/format-number';
import { useRegisterConsultantMutation, useUpdateConsultantByIdMutation } from 'src/services/user/consultant-api';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import { getFileExtension, getModifiedMobileNumber, validateNIC, validatePhoneNumber } from 'src/utils/custom-function';
import { useGetUploadURLMutation } from 'src/services/profile/profille-api';
import { useUploadToS3Mutation } from 'src/services/base-api';
import { formatDate } from 'src/utils/format-time';
// ----------------------------------------------------------------------

export default function ConsultantForm({ consultant, open, onClose, onSuccess }) {
    const mdUp = useResponsive('up', 'md');

    const router = useRouter();

    const { enqueueSnackbar } = useSnackbar();

    const [imageFile, setImageFile] = useState(null);

    const NewProductSchema = Yup.object().shape({
        fname: Yup.string().required('First name cannot be empty or invalid'),
        lname: Yup.string().required('Last name cannot be empty or invalid'),
        email: Yup.string().required('Email is required').email('Email must be a valid email address'),
        contactNumber: Yup.string().required('Contact number cannot be empty').test('isValidContactNumber', 'Invalid contact number', () => {
            const isValid = validatePhoneNumber(values.contactNumber);
            return isValid;
        })
    });

    const [updateConsultant, { isSuccess: isSuccessUpdateConsultant, error: errorUpdateConsultant }] = useUpdateConsultantByIdMutation();

    const [createConsultant, { isSuccess: isSuccessCreateConsultant, error: errorCreateConsultant }] = useRegisterConsultantMutation();

    const [getUploadURL, { error: getUploadURLError, data: getUploadURLData, isSuccess: getUploadURLSuccess }] = useGetUploadURLMutation();

    const [uploadToS3, { error: uploadToS3Error, isSuccess: uploadToS3Success }] = useUploadToS3Mutation();

    const defaultValues = useMemo(
        () => ({
            photoURL: consultant?.profile_img || null,
            fname: consultant?.first_name || '',
            lname: consultant?.last_name || '',
            email: consultant?.email || '',
            contactNumber: consultant?.phone_no || ''
        }),
        [consultant]
    );

    const methods = useForm({
        resolver: yupResolver(NewProductSchema),
        defaultValues,
    });

    const {
        setValue,
        reset,
        watch,
        handleSubmit,
        setError,
        formState: { isSubmitting, },
    } = methods;

    const values = watch();

    const [inSubmission, setInSubmission] = useState(false);

    const onSubmit = handleSubmit(async (data) => {
        setInSubmission(true);

        try {
            if (imageFile) {
                const fileExtension = getFileExtension(data.photoURL);
                await getUploadURL({ extension: fileExtension, type: "PROFILE_IMG" });
            }
            else if (consultant) {
                let body;

                if(values.photoURL){
                    body = {
                        'phone_no': getModifiedMobileNumber(data.contactNumber),
                        'profile_img': values.photoURL,
                        'display_name': values.fname,
                        'first_name': values.fname,
                        'last_name': values.lname
                    }
                }
                else{
                    body = {
                        'phone_no': getModifiedMobileNumber(data.contactNumber),
                        'display_name': values.fname,
                        'first_name': values.fname,
                        'last_name': values.lname
                    }
                }
                
                console.log(body);
                await updateConsultant({ id: consultant.id, body });
            }
            else {
                const body = {
                    'email': values.email,
                    'display_name': values.fname,
                    'first_name': values.fname,
                    'last_name': values.lname,
                    'phone_no': getModifiedMobileNumber(values.contactNumber)
                };

                console.log(body);
                await createConsultant(body);
            }

        } catch (error) {
            console.error(error);
            setInSubmission(false);
        }
    });

    useEffect(() => {
        if (getUploadURLSuccess && imageFile) {
            uploadToS3({ uploadUrl: getUploadURLData.uploadUrl, file: imageFile });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUploadURLSuccess])

    useEffect(() => {
        if (uploadToS3Success && getUploadURLData) {
            setInSubmission(true);

            if (consultant){
                const body = {
                    'phone_no': getModifiedMobileNumber(values.contactNumber),
                    'profile_img': getUploadURLData.downloadUrl,
                    'display_name': values.fname,
                    'first_name': values.fname,
                    'last_name': values.lname
                }

                console.log(body);
                updateConsultant({ id: consultant.id, body });
            }
            else{
                const body = {
                    'email': values.email,
                    'display_name': values.fname,
                    'first_name': values.fname,
                    'last_name': values.lname,
                    'phone_no': getModifiedMobileNumber(values.contactNumber),
                    'profile_img': getUploadURLData.downloadUrl
                };
    
                console.log(body);
                createConsultant(body);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadToS3Success])

    useEffect(() => {
        if (getUploadURLError || uploadToS3Error) {
            setInSubmission(false);
            enqueueSnackbar('Image upload Failed!', { variant: 'error' });
        }
    }, [enqueueSnackbar, getUploadURLError, uploadToS3Error]);

    useEffect(() => {
        const currentURL = window.location.href;
        const parts = currentURL.split("/");
        const value = parts[parts.length - 1];
        if (value === 'edit') {
            if (!consultant) {
                router.push(paths.dashboard.user.root);
            }
        }
    }, [router, consultant]);

    const handleDrop = useCallback(
        (acceptedFiles) => {
            const file = acceptedFiles[0];

            const newFile = Object.assign(file, {
                preview: URL.createObjectURL(file),
            });

            if (file) {
                setValue('photoURL', newFile, { shouldValidate: true });
                setImageFile(file);
            }
        },
        [setValue]
    );

    useEffect(() => {
        if (consultant) {
            reset(defaultValues);
        }
    }, [consultant, defaultValues, reset]);

    useEffect(() => {
        setInSubmission(false);

        if (isSuccessUpdateConsultant || isSuccessCreateConsultant) {
            reset();
            enqueueSnackbar(consultant ? 'Update success!' : 'Create success!');
            onClose();
            onSuccess();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccessCreateConsultant, isSuccessUpdateConsultant])

    useEffect(() => {
        setInSubmission(false);

        if (errorCreateConsultant) {
            enqueueSnackbar(errorCreateConsultant.data?.message, { variant: 'error' });
        }
        else if (errorUpdateConsultant) {
            enqueueSnackbar(errorUpdateConsultant.data?.message, { variant: 'error' });
        }
    }, [enqueueSnackbar, errorCreateConsultant, errorUpdateConsultant])

    const renderBasicInfo = (
        <>
            {mdUp && (
                <Grid md={4}>
                    <Typography variant="h6" sx={{ mb: 0.5 }}>
                        Basic Info
                    </Typography>
                </Grid>
            )}

            <Grid xs={12} md={8}>
                <Card>
                    {!mdUp && <CardHeader title="Basic Info" />}

                    <Stack spacing={3} sx={{ p: 3 }}>
                        <RHFUploadAvatar
                            name="photoURL"
                            maxSize={3145728}
                            onDrop={handleDrop}
                            helperText={
                                <Typography
                                    variant="caption"
                                    sx={{
                                        mt: 3,
                                        mx: 'auto',
                                        display: 'block',
                                        textAlign: 'center',
                                        color: 'text.disabled',
                                    }}
                                >
                                    Allowed *.jpeg, *.jpg, *.png, *.gif
                                    <br /> max size of {fData(3145728)}
                                </Typography>
                            }
                        />

                        <RHFTextField name="fname" label="First Name" />
                        <RHFTextField name="lname" label="Last Name" />
                        <RHFTextField name="email" label="Email" disabled={consultant}/>
                        <RHFTextField name="contactNumber" label="Contact Number" />
                    </Stack>
                </Card>
            </Grid>
        </>
    );

    return (
        <Dialog
            fullWidth
            maxWidth={false}
            open={open}
            onClose={() => {
                reset();
                onClose();
            }}
            PaperProps={{
                sx: { maxWidth: 900 }
            }}
        >
            <DialogTitle>
                <Typography variant="h6" gutterBottom>
                    {
                        consultant ? "Edit" : "Create"
                    }
                </Typography>
            </DialogTitle>

            <FormProvider methods={methods} onSubmit={onSubmit}>
                <Grid container spacing={3} sx={{px: 3}}>
                    {renderBasicInfo}
                </Grid>

                <DialogActions>
                    <Button variant="outlined" onClick={() => {
                        onClose();
                        reset();
                    }}
                    >
                        Close
                    </Button>

                    <LoadingButton type="submit" variant="contained" size="medium" loading={inSubmission}>
                        {!consultant ? 'Create' : 'Update'}
                    </LoadingButton>
                </DialogActions>
            </FormProvider>
        </Dialog>
    );
}

ConsultantForm.propTypes = {
    consultant: PropTypes.object,
    open: PropTypes.func, 
    onClose: PropTypes.func, 
    onSuccess: PropTypes.func
};
