import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useMemo, useEffect, useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { MenuItem } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
// routes
import { useSnackbar } from 'src/components/snackbar';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// _mock
// components
import FormProvider, {
    RHFCheckbox,
    RHFSelect,
    RHFTextField,
} from 'src/components/hook-form';
import RHFDatepicker from 'src/components/hook-form/rhf-date-picker';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import { formatDate } from 'src/utils/format-time';
import { useCreateTourDriverMutation, useUpdateTourDriverByIdMutation } from 'src/services/driver/driver-api';
import Iconify from 'src/components/iconify';
import { validatePhoneNumber } from 'src/utils/custom-function';
// assets
import { DriverType } from 'src/assets/data';
import ImageUpload from '../driver-guide/image_upload';
// ----------------------------------------------------------------------
const vehicleTypeList = [
    {id: 0, label: "Car", value: "CAR"},
    {id: 1, label: "Van", value: "VAN"},
    {id: 2, label: "Van - High Roof", value: "VAN_HIGH_ROOF"},
    {id: 3, label: "Mini Bus", value: "MINI_BUS"}
];

export default function DriverForm({ driver, open, onClose, onSuccess }) {

    const mdUp = useResponsive('up', 'md');

    const router = useRouter();

    const { enqueueSnackbar } = useSnackbar();

    const NewProductSchema = Yup.object().shape({
        driverId: Yup.string().required('Driver id cannot be empty or invalid').matches(/^[a-zA-Z ]+-[a-zA-Z0-9-]+$/, 'Invalid driver ID format'),
        driver_type: Yup.string().required('Driver type cannot be empty'),
        mobileNumber: Yup.string().required('Mobile number cannot be empty').test('isValidMobileNumber', 'Invalid mobile number', () => {
            const isValid = checkValidateMobile();
            return isValid;
        }),
        vehicle_type: Yup.string().required('Vehicle type cannot be empty'),
        web_portfolio: Yup.string().test('isValidWebPortfolio', 'Invalid driver portfolio url', () => {
            const isValid = checkValidateDriverPortfolio();
            return isValid;
        })
    });

    const [updateDriver, { isSuccess: isSuccessUpdateDriver, error: errorUpdateDriver }] = useUpdateTourDriverByIdMutation();

    const [createDriver, { isSuccess: isSuccessCreateDriver, error: errorCreateDriver }] = useCreateTourDriverMutation();

    const [userActive, setUserActive] = useState(true);

    const defaultValues = useMemo(
        () => ({
            driverId: driver? driver.driver_name.concat("-").concat(driver.driver_id) : '',
            driver_type: driver?.driver_type || null,
            mobileNumber: driver?.mobile_no || '',
            profileImage: driver?.profile_img || null,
            vehicle_type: driver?.vehicle_type || null,
            web_portfolio: driver?.web_link || '',
            active: driver != null ? setUserActive(driver.active) : setUserActive(true)
        }),
        [driver]
    );

    const methods = useForm({
        resolver: yupResolver(NewProductSchema),
        defaultValues,
    });

    const {
        reset,
        watch,
        handleSubmit,
        formState: { isSubmitting, },
    } = methods;

    const values = watch();

    const checkValidateMobile = () => validatePhoneNumber(values.mobileNumber);

    const checkValidateDriverPortfolio = () => {
        const url = values.web_portfolio.trim();

        if(url === ""){
            return true;
        }

        const regexQuery = "^(https?:\\/\\/)?((([-a-z0-9]{1,63}\\.)*?[a-z0-9]([-a-z0-9]{0,253}[a-z0-9])?\\.[a-z]{2,63})|((\\d{1,3}\\.){3}\\d{1,3}))(:\\d{1,5})?((\\/|\\?)((%[0-9a-f]{2})|[-\\w\\+\\.\\?\\/@~#&=])*)?$";
        const reg = new RegExp(regexQuery,"i");
        return reg.test(url);
    }

    const getDriverName = (value) => {
        const firstHyphenIndex = value.indexOf("-");
        return value.substring(0, firstHyphenIndex);
    }

    const getDriverID = (value) => {
        const firstHyphenIndex = value.indexOf("-");
        return value.substring(firstHyphenIndex + 1, value.length);
    }

    const onSubmit = handleSubmit(async (data) => {
        const driverName = getDriverName(data.driverId);
        const driverID = getDriverID(data.driverId);

        try {
            if (driver) {
                const body = {
                    "active": userActive,
                    "driver_name": driver.driver_name,
                    "driver_type": data.driver_type,
                    "id": driver.id,
                    "mobile_no": data.mobileNumber,
                    "profile_img": data.profileImage,
                    "vehicle_type": data.vehicle_type,
                    "web_link": data.web_portfolio
                };
    
                console.log(body);

                await updateDriver({ body });
            } else {
                const body = {
                    "driver_id": driverID,
                    "driver_name": driverName,
                    "vehicle_type": data.vehicle_type,
                    "active": userActive,
                    "mobile_no": data.mobileNumber,
                    "profile_img": data.profileImage,
                    "driver_type": data.driver_type,
                    "web_link": data.web_portfolio
                };
    
                console.log(body);

                await createDriver(body);
            }
        } catch (error) {
            console.error(error);
        }
    });

    useEffect(() => {
        const currentURL = window.location.href;
        const parts = currentURL.split("/");
        const value = parts[parts.length - 1];
        if (value === 'edit') {
            if (!driver) {
                router.push(paths.dashboard.user.driverGuide);
            }
        }
    }, [router, driver]);

    useEffect(() => {
        if (driver) {
            reset(defaultValues);
        }
    }, [defaultValues, reset, driver]);

    useEffect(() => {
        if (isSuccessUpdateDriver || isSuccessCreateDriver) {
            enqueueSnackbar(isSuccessCreateDriver ? 'Successfully created!' : 'Successfully updated!');
            // router.push(paths.dashboard.user.driverGuide);

            reset();
            onClose();

            if(onSuccess) onSuccess();
        }

        if (errorUpdateDriver || errorCreateDriver) {
            enqueueSnackbar(errorCreateDriver ? errorCreateDriver?.data?.message : errorUpdateDriver?.data?.message, { variant: 'error' });
        }

    }, [enqueueSnackbar, onSuccess, errorCreateDriver, errorUpdateDriver, isSuccessCreateDriver, isSuccessUpdateDriver, reset, onClose])

    const handleChangeStatus = useCallback((event) => {
        setUserActive(status => !status);
    }, []);
    
    const renderBasicInfo = (
        <>
            {mdUp && (
                <Grid md={4}>
                    <Typography variant="h6" sx={{ mb: 0.5 }}>
                        Basic Info
                    </Typography>
                </Grid>
            )}

            <Grid xs={12} md={8}>
                <Card>
                    {!mdUp && <CardHeader title="Basic Info" />}

                    <Stack spacing={3} sx={{ p: 3 }}>
                        <RHFTextField  
                            name="driverId" 
                            label="Driver" 
                            disabled={driver}
                            helperText={
                                <Stack component="span" direction="row" alignItems="center">
                                    <Iconify icon="eva:info-fill" width={16} sx={{ mr: 0.5 }} /> Driver should be in the following format: Drivername-Driverid
                                </Stack>
                            }
                            required
                        />

                        <RHFSelect
                            fullWidth
                            name="driver_type"
                            label="Driver Type"
                            InputLabelProps={{ shrink: true }}
                            PaperPropsSx={{ textTransform: 'capitalize' }}
                            required
                        >
                            {DriverType.map((option) => (
                                <MenuItem key={option.label} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </RHFSelect>

                        <RHFTextField 
                            name="mobileNumber" 
                            label="Mobile Number" 
                            required
                        />

                        <Stack spacing={1.5}>
                            <Typography variant="subtitle2">Profile Image</Typography>
                            <ImageUpload name="profileImage" type='PROFILE_IMG'/>
                        </Stack>
                    </Stack>
                </Card>
            </Grid>
        </>
    );

    const renderVehicleInfo = (
        <>
            {mdUp && (
                <Grid md={4}>
                    <Typography variant="h6" sx={{ mb: 0.5 }}>
                        Vehicle Info
                    </Typography>
                </Grid>
            )}

            <Grid xs={12} md={8}>
                <Card>
                    {!mdUp && <CardHeader title="Vehicle Info" />}

                    <Stack spacing={3} sx={{ p: 3 }}>
                        <RHFSelect
                            fullWidth
                            name="vehicle_type"
                            label="Vehicle Type"
                            InputLabelProps={{ shrink: true }}
                            PaperPropsSx={{ textTransform: 'capitalize' }}
                            required
                        >
                            {vehicleTypeList.map((option) => (
                                <MenuItem key={option.id} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </RHFSelect>
                    </Stack>
                </Card>
            </Grid>
        </>
    );

    const renderSocialInfo = (
        <>
            {mdUp && (
                <Grid md={4}>
                    <Typography variant="h6" sx={{ mb: 0.5 }}>
                        Social Info
                    </Typography>
                </Grid>
            )}

            <Grid xs={12} md={8}>
                <Card>
                    {!mdUp && <CardHeader title="Social Info" />}

                    <Stack spacing={3} sx={{ p: 3 }}>
                        <RHFTextField  
                            name="web_portfolio" 
                            label="Driver web portfolio" 
                        />
                    </Stack>
                </Card>
            </Grid>
        </>
    );

    const renderStatusInfo = (
        <>
            {mdUp && (
                <Grid md={4}>
                    <Typography variant="h6" sx={{ mb: 0.5 }}>
                        Driver Status
                    </Typography>
                </Grid>
            )}

            <Grid xs={12} md={8}>
                <Card>
                    {!mdUp && <CardHeader title="Driver Status" />}

                    <Stack spacing={3} sx={{ p: 3 }}>
                        <FormControlLabel
                            control={<Switch checked={userActive}/>}
                            name='active'
                            label="Activate"
                            sx={{ flexGrow: 1, pl: 3 }}
                            onChange={handleChangeStatus}
                        />
                    </Stack>
                </Card>
            </Grid>
        </>
    );

    const renderActions = (
        <>
            {mdUp && <Grid md={4} />}
            <Grid xs={12} md={8} sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <Box
                    sx={{ flexGrow: 1, pl: 3 }}
                />

                <Button variant="outlined" size="large" sx={{mr: 2}} onClick={() => {
                    onClose();
                    reset();
                }}>
                    Close
                </Button>

                <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
                    {!driver ? 'Create' : 'Save Changes'}
                </LoadingButton>
            </Grid>
        </>
    );

    return (
        <Dialog
            fullWidth
            maxWidth={false}
            open={open}
            onClose={() => {
                reset();
                onClose();
            }}
            PaperProps={{
            sx: { maxWidth: 900 },
            }}
        >
            <DialogTitle>
                <Typography variant="h6" gutterBottom>
                    {
                        driver ? 'Edit' : 'New'
                    }
                </Typography>
            </DialogTitle>

            <FormProvider methods={methods} onSubmit={onSubmit}>
                <DialogContent>
                    <Grid container spacing={3} sx={{paddingX: 1}}>
                        {renderBasicInfo}

                        {renderVehicleInfo}

                        {renderSocialInfo}

                        {renderStatusInfo}

                        {/* {renderActions} */}
                    </Grid>
                </DialogContent>

                <DialogActions>
                    {renderActions}
                </DialogActions>
            </FormProvider>
        </Dialog>
    );
}

DriverForm.propTypes = {
    driver: PropTypes.object,
    open: PropTypes.func, 
    onClose: PropTypes.func,
    onSuccess: PropTypes.func
};
