import React, { useCallback, useEffect, useState } from 'react'
import { Card, Table, TableBody, TableContainer, Container, Button } from '@mui/material';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import { RouterLink } from 'src/routes/components';
import { LoadingScreen } from 'src/components/loading-screen';
import Scrollbar from 'src/components/scrollbar';
import { TableNoData, TablePaginationCustom, useTable, TableHeadCustom } from 'src/components/table';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import { RoleBasedGuard } from 'src/auth/guard';
import { useAuthContext } from 'src/auth/hooks';
import { useGetAllTourDealTypesMutation } from 'src/services/affordable/tour-deals/tour-deals-api';
import { useBoolean } from 'src/hooks/use-boolean';
import TourDealsTypeTableRow from './tours-deals-type-table-row';
import AffordableTableToolbar from './affordable-table-toolbar';
import TourDealTypeForm from './tour-deal-type-form';
import ErrorView from '../error/error-view';

const TABLE_HEAD = [
    { id: 'name', label: 'Tour Deal Type' },
    { id: 'action', label: ''}
];

const defaultFilters = {
    name: '',
    ref: ''
};

export default function TourDealsTypeList() {
    const settings = useSettingsContext();

    const router = useRouter();

    const table = useTable({ defaultOrderBy: 'name' });

    const [tableData, setTableData] = useState([]);
    const [totalElements, setTotalElements] = useState(0);

    const [filters, setFilters] = useState(defaultFilters);

    const [searchTimer, setSearchTimer] = useState(null);
    const [searchKey, setSearchKey] = useState(null);

    const { isUserRoleHasAccess } = useAuthContext();

    const [getAllTourDealType, { isLoading, isSuccess, data, error }] = useGetAllTourDealTypesMutation();

    const canReset =
        !!filters.name ||
        !!filters.ref; 

    const notFound = (!tableData.length && canReset) || !tableData.length;

    const quickCreate = useBoolean();
    const quickEdit = useBoolean();
    const [viewData, setViewData] = useState(null);

    const handleResetFilters = useCallback(() => {
        setSearchKey(null);
        setFilters(defaultFilters);
    }, []);

    const handleFilters = useCallback((name, value) => {
        table.onResetPage();

        setSearchKey(value);

        if (searchTimer) {
            clearTimeout(searchTimer);
        }

        if (!value) {
            handleResetFilters();
        }

        const newSearchTimer = setTimeout(() => {
            getAllTourDealType({ page: table.page, size: table.rowsPerPage, key: value });
        }, 1000);

        setSearchTimer(newSearchTimer);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[handleResetFilters, searchTimer, table]);

    // Get All
    useEffect(() => {
        getAllTourDealType({ page: table.page, size: table.rowsPerPage, key: searchKey });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getAllTourDealType, table.page, table.rowsPerPage]);

    const handleCreateView = useCallback(() => {
        quickCreate.onTrue();
    },[quickCreate]);

    const handleEditRow = useCallback((row) => {
        if(!quickEdit.value){
            setViewData(row);
            quickEdit.onTrue();
        }
    },[quickEdit]);

    useEffect(() => {
        if (isSuccess) {
            setTableData(data.content)
            setTotalElements(data.totalElements);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess, data]);

    const editRefreshCall = useCallback(() => {
        handleResetFilters();
        getAllTourDealType({ page: table.page, size: table.rowsPerPage, key: searchKey });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleResetFilters]);

    let tourDealsTypeContent;

    if (isLoading) {
        tourDealsTypeContent = <LoadingScreen />
    }
    else if (error) {
        tourDealsTypeContent = <>
            <ErrorView error={error?.data} showTryAgain={false}/>
        </>
    }
    else if (isSuccess) {
        tourDealsTypeContent = (
            <Card>
                <AffordableTableToolbar
                    value={searchKey}
                    onFilters={handleFilters}
                />

                <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                    <Scrollbar>
                        <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 800 }} stickyHeader>
                            <TableHeadCustom
                                order={table.order}
                                orderBy={table.orderBy}
                                headLabel={TABLE_HEAD}
                                rowCount={tableData.length}
                                numSelected={table.selected.length}
                                onSort={table.onSort}
                                onSelectAllRows={(checked) =>
                                table.onSelectAllRows(
                                checked,
                                tableData.map((row) => row.id)
                                )}
                            />

                            <TableBody>
                                {tableData
                                    .map((row) => (
                                        <TourDealsTypeTableRow
                                            key={row.id}
                                            row={row}
                                            onEditRow={() => handleEditRow(row)}
                                        />
                                    ))}
                                <TableNoData notFound={notFound} />
                            </TableBody>
                        </Table>
                    </Scrollbar>
                </TableContainer>
                {tableData.length > 0 &&
                    <TablePaginationCustom
                        count={totalElements}
                        page={table.page}
                        rowsPerPage={table.rowsPerPage}
                        onPageChange={table.onChangePage}
                        onRowsPerPageChange={table.onChangeRowsPerPage}
                        dense={table.dense}
                        onChangeDense={table.onChangeDense}
                    />}
            </Card>
        );
    }

    return (
        <>
            <Container maxWidth={settings.themeStretch ? false : 'lg'}>
                <CustomBreadcrumbs
                    heading="Tour Deals Type"
                    links={[
                        {
                            name: 'Dashboard',
                            href: paths.dashboard.driverReview.root,
                        },
                        {
                            name: 'Tour Deals Type',
                            href: paths.dashboard.affordable.tourDealsType,
                        },
                        {
                            name: 'List'
                        },
                    ]}
                    action={
                        isUserRoleHasAccess(3, 1)
                        ?
                        <Button
                            component={RouterLink}
                            // href={paths.dashboard.affordable.createTourDealType}
                            onClick={handleCreateView}
                            variant="contained"
                            startIcon={<Iconify icon="mingcute:add-line" />}
                        >
                            Add Deal Type
                        </Button>
                        :
                        <></>
                    }
                    sx={{
                        mb: { xs: 3, md: 5 },
                    }}
                />

                <RoleBasedGuard hasContent mainMenuOrder={3} subMenuOrder={1} sx={{ py: 10 }}>
                    {tourDealsTypeContent}
                </RoleBasedGuard>
            </Container>

            <TourDealTypeForm open={quickCreate.value} onClose={quickCreate.onFalse} onSuccess={editRefreshCall}/>
            <TourDealTypeForm tourDealType={viewData} open={quickEdit.value} onClose={quickEdit.onFalse} onSuccess={editRefreshCall}/>
        </>
    );
}
