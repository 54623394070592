import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useMemo, useEffect, useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import { MenuItem, Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
// routes
import { useSnackbar } from 'src/components/snackbar';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// _mock
// components
import FormProvider, {
    RHFSelect,
    RHFTextField,
    RHFUploadAvatar,
} from 'src/components/hook-form';
import RHFDatepicker from 'src/components/hook-form/rhf-date-picker';
import { district } from 'src/assets/data';
import { fData } from 'src/utils/format-number';
import { useRegisterStaffMutation, useUpdateStaffByIdMutation } from 'src/services/user/staff-api';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import { getFileExtension, getModifiedMobileNumber, validateNIC, validatePhoneNumber } from 'src/utils/custom-function';
import { useGetUploadURLMutation } from 'src/services/profile/profille-api';
import { useUploadToS3Mutation } from 'src/services/base-api';
import { formatDate } from 'src/utils/format-time';
import BankDetails from '../bank-details';


// ----------------------------------------------------------------------


const civilStatusList = ["SINGLE", "MARRIED", "DIVORCED"];

const employmentCategoryList = [
    "FULL_TIME",
    "PART_TIME",
    "FREELANCER",
    "INTERNSHIP"
];

const workTypeList = ["ON_SITE", "REMOTE", "HYBRID"];

const designationList = ["SUPER_ADMIN", "ADMIN"];


const relationShipList = ['Father', 'Mother', 'Brother', 'Sister', 'Guardian', 'Grand Father', 'Grand Mother', 'Other'];

export default function StaffForm({ staff, isView, isEdit, open, onClose, onSuccess }) {

    const mdUp = useResponsive('up', 'md');

    const router = useRouter();

    const { enqueueSnackbar } = useSnackbar();

    const [imageFile, setImageFile] = useState(null);

    const NewProductSchema = Yup.object().shape({
        firstName: Yup.string().required('First Name cannot be empty or invalid'),
        lastName: Yup.string().required('Last Name cannot be empty or invalid'),
        birthDate: Yup.date()
            .required('Date of birth cannot be empty')
            .typeError("Please select a valid date")
            .nullable(),
        address: Yup.string().required('Address cannot be empty'),
        nic: Yup.string().required('NIC cannot be empty').test('isValidNIC', 'Invalid NIC', () => {
            const isValid = checkValidateNIC();
            return isValid;
        }),
        email: Yup.string().required('Email is required').email('Email must be a valid email address'),
        mobileNumber: Yup.string().required('Mobile number cannot be empty').test('isValidMobileNumber', 'Invalid mobile number', () => {
            const isValid = checkValidateMobile('mobile');
            return isValid;
        }),
        whatsupNumber: Yup.string().required('Whatsup number cannot be empty')
            .test('isValidWhatsupNumber', 'Invalid Whatsup number', () => {
                const isValid = checkValidateMobile('whatsup');
                return isValid;
            }),
        district: Yup.string().required('District cannot be empty'),
        civilStatus: Yup.string().required('Civil status cannot be empty'),
        employmentCategory: Yup.string().required('Employment category cannot be empty'),
        workType: Yup.string().required('Work type cannot be empty'),
        role: Yup.string().required('Role cannot be empty'),
        emeName: Yup.string().required('Emergency contact person name cannot be empty or invalid'),
        emeRelationship: Yup.string().required('Emergency contact person relationship cannot be empty or invalid'),
        emeNumber: Yup.string().required('Emergency contact person number cannot be empty')
            .test('isValidEmeNumber', 'Invalid contact person number', () => {
                const isValid = checkValidateMobile('emeNumber');
                return isValid;
            }),
        bankDetails: Yup.array().of(
            Yup.object().shape({
                account_holder_name: Yup.string().required('Name mentioned in bank account cannot be empty or invalid'),
                bank_name: Yup.string().required('Bank name cannot be empty'),
                account_number: Yup.number()
                    .typeError('Account number must be a number')
                    .required('Account number cannot be empty or invalid'),
                branch: Yup.string().required('Branch name cannot be empty or invalid'),
            })
        ),
    });

    const [updateStaff, { isSuccess: isSuccessUpdateStaff, error: errorUpdateStaff }] = useUpdateStaffByIdMutation();

    const [createStaff, { isSuccess: isSuccessCreateStaff, error: errorCreateStaff }] = useRegisterStaffMutation();

    const [getUploadURL, { error: getUploadURLError, data: getUploadURLData, isSuccess: getUploadURLSuccess }] = useGetUploadURLMutation();

    const [uploadToS3, { error: uploadToS3Error, isSuccess: uploadToS3Success }] = useUploadToS3Mutation();

    const defaultValues = useMemo(
        () => ({
            photoURL: staff?.user_profile?.profile_img || null,
            firstName: staff?.user_profile?.first_name || '',
            lastName: staff?.user_profile?.last_name || '',
            displayName: staff?.user_profile?.display_name || '',
            birthDate: new Date(staff?.user_profile?.dob) || null,
            address: staff?.user_profile?.address.address || '',
            nic: staff?.user_profile?.nic || '',
            email: staff?.email || '',
            mobileNumber: staff?.user_profile?.phone_no || '',
            whatsupNumber: staff?.user_profile?.whatsapp_no || '',
            district: staff?.user_profile?.address.district || '',
            civilStatus: staff?.civil_status || '',
            employmentCategory: staff?.employment_category || '',
            workType: staff?.work_type || '',
            role: staff?.role || '',
            emeName: staff?.emergency_contacts?.name || '',
            emeRelationship: staff?.emergency_contacts?.relation_ship || '',
            emeNumber: staff?.emergency_contacts?.number || '',
            bankDetails: staff?.bank_detail?.length
                ? staff?.bank_detail
                : [
                    {
                        account_holder_name: '',
                        bank_name: '',
                        account_number: '',
                        branch: ''
                    }

                ],
        }),
        [staff]
    );

    const methods = useForm({
        resolver: yupResolver(NewProductSchema),
        defaultValues,
    });

    const {
        setValue,
        reset,
        watch,
        handleSubmit,
        setError,
        formState: { isSubmitting, },
    } = methods;

    const values = watch();

    const DESIGNATION = "STAFF";

    const [inSubmission, setInSubmission] = useState(false);

    const onSubmit = handleSubmit(async (data) => {
        setInSubmission(true);

        try {
            if (imageFile) {
                const fileExtension = getFileExtension(data.photoURL);
                await getUploadURL({ extension: fileExtension, type: "PROFILE_IMG" });
            }
            else if (staff) {
                let body;

                if(values.photoURL){
                    body = {
                        "employment_category": data.employmentCategory,
                        "work_type": data.workType,
                        "designation": data.role,
                        'address': data.address,
                        'district': data.district,
                        'phone_no': getModifiedMobileNumber(data.mobileNumber),
                        'role': data.role,
                        'whatsapp_no': getModifiedMobileNumber(data.whatsupNumber),
                        'bank_detail': data.bankDetails,
                        'profile_img': values.photoURL,
                    }
                }
                else{
                    body = {
                        "employment_category": data.employmentCategory,
                        "work_type": data.workType,
                        "designation": data.role,
                        'address': data.address,
                        'district': data.district,
                        'phone_no': getModifiedMobileNumber(data.mobileNumber),
                        'role': data.role,
                        'whatsapp_no': getModifiedMobileNumber(data.whatsupNumber),
                        'bank_detail': data.bankDetails
                    }
                }
                
                await updateStaff({ id: staff.id, body });
            }
            else {
                const body = {
                    'address': values.address,
                    'bank_detail': values.bankDetails,
                    'designation': DESIGNATION,
                    'civil_status': values.civilStatus,
                    'display_name': values.displayName,
                    'district': values.district,
                    'dob': formatDate(values.birthDate),
                    'email': values.email,
                    'emergency_contact_name': values.emeName,
                    'emergency_contact_number': getModifiedMobileNumber(values.emeNumber),
                    'emergency_contact_relation_ship': values.emeRelationship,
                    'employment_category': values.employmentCategory,
                    'first_name': values.firstName,
                    'last_name': values.lastName,
                    'nic': values.nic,
                    'phone_no': getModifiedMobileNumber(values.mobileNumber),
                    'role': values.role,
                    'whatsapp_no': getModifiedMobileNumber(values.whatsupNumber),
                    'work_type': values.workType
                };

                await createStaff(body);
            }

        } catch (error) {
            console.error(error);
            setInSubmission(false);
        }
    });

    const checkValidateNIC = () => validateNIC(values.nic);

    const checkValidateMobile = (type) => {
        if (type === 'mobile') {
            return validatePhoneNumber(values.mobileNumber);
        }
        if (type === 'whatsup') {
            return validatePhoneNumber(values.whatsupNumber);
        }
        if (type === 'emeNumber') {
            return validatePhoneNumber(values.emeNumber);
        }
        return false;
    };

    useEffect(() => {
        if (getUploadURLSuccess && imageFile) {
            uploadToS3({ uploadUrl: getUploadURLData.uploadUrl, file: imageFile });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getUploadURLSuccess])

    useEffect(() => {
        if (uploadToS3Success && getUploadURLData) {
            setInSubmission(true);

            if (staff){
                const body = {
                    "employment_category": values.employmentCategory,
                    "work_type": values.workType,
                    "designation": DESIGNATION,
                    'address': values.address,
                    'district': values.district,
                    'phone_no': getModifiedMobileNumber(values.mobileNumber),
                    'role': values.role,
                    'whatsapp_no': getModifiedMobileNumber(values.whatsupNumber),
                    'bank_detail': values.bankDetails,
                    'profile_img': getUploadURLData.downloadUrl,
                }

                updateStaff({ id: staff.id, body });
            }
            else{
                const body = {
                    'address': values.address,
                    'bank_detail': values.bankDetails,
                    'designation': DESIGNATION,
                    'civil_status': values.civilStatus,
                    'display_name': values.displayName,
                    'district': values.district,
                    'dob': formatDate(values.birthDate),
                    'email': values.email,
                    'emergency_contact_name': values.emeName,
                    'emergency_contact_number': getModifiedMobileNumber(values.emeNumber),
                    'emergency_contact_relation_ship': values.emeRelationship,
                    'employment_category': values.employmentCategory,
                    'first_name': values.firstName,
                    'last_name': values.lastName,
                    'nic': values.nic,
                    'phone_no': getModifiedMobileNumber(values.mobileNumber),
                    'profile_img': getUploadURLData.downloadUrl,
                    'role': values.role,
                    'whatsapp_no': getModifiedMobileNumber(values.whatsupNumber),
                    'work_type': values.workType
                };
    
                createStaff(body);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadToS3Success])

    useEffect(() => {
        if (getUploadURLError || uploadToS3Error) {
            setInSubmission(false);
            enqueueSnackbar('Image upload Failed!', { variant: 'error' });
        }
    }, [enqueueSnackbar, getUploadURLError, uploadToS3Error]);

    useEffect(() => {
        const currentURL = window.location.href;
        const parts = currentURL.split("/");
        const value = parts[parts.length - 1];
        if (value === 'edit') {
            if (!staff) {
                router.push(paths.dashboard.user.root);
            }
        }
    }, [router, staff]);

    const handleDrop = useCallback(
        (acceptedFiles) => {
            const file = acceptedFiles[0];

            const newFile = Object.assign(file, {
                preview: URL.createObjectURL(file),
            });

            if (file) {
                setValue('photoURL', newFile, { shouldValidate: true });
                setImageFile(file);
            }
        },
        [setValue]
    );

    useEffect(() => {
        if (staff) {
            reset(defaultValues);
        }
    }, [staff, defaultValues, reset]);

    useEffect(() => {
        setInSubmission(false);

        if (isSuccessUpdateStaff || isSuccessCreateStaff) {
            reset();
            enqueueSnackbar(staff ? 'Update success!' : 'Create success!');
            onClose();
            onSuccess();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccessCreateStaff, isSuccessUpdateStaff])

    useEffect(() => {
        setInSubmission(false);

        if (errorCreateStaff) {
            if (errorCreateStaff?.data?.message === 'Email Already registered') {
                setError('email', { message: errorCreateStaff?.data?.message, type: 'required', shouldFocus: true })
            } else if (errorCreateStaff?.data?.message === 'Phone Number Already registered') {
                setError('mobileNumber', { message: errorCreateStaff?.data?.message, type: 'required', shouldFocus: true })
            }
            else {
                enqueueSnackbar(errorCreateStaff ? errorCreateStaff?.data?.message : errorUpdateStaff?.data?.message, { variant: 'error' });
            }
        }
        else if (errorUpdateStaff) {
            enqueueSnackbar(errorUpdateStaff?.data?.message, { variant: 'error' });
        }

    }, [enqueueSnackbar, errorCreateStaff, errorUpdateStaff, setError])

    const renderBasicInfo = (
        <>
            {mdUp && (
                <Grid md={4}>
                    <Typography variant="h6" sx={{ mb: 0.5 }}>
                        Basic Info
                    </Typography>
                </Grid>
            )}

            <Grid xs={12} md={8}>
                <Card>
                    {!mdUp && <CardHeader title="Basic Info" />}

                    <Stack spacing={3} sx={{ p: 3 }}>
                        {isView && <RHFUploadAvatar
                            name="photoURL"
                            maxSize={3145728}
                            disabled
                            helperText={!isView ? (
                                <Typography
                                    variant="caption"
                                    sx={{
                                        mt: 3,
                                        mx: 'auto',
                                        display: 'block',
                                        textAlign: 'center',
                                        color: 'text.disabled',
                                    }}
                                >
                                    Allowed *.jpeg, *.jpg, *.png, *.gif
                                    <br /> max size of {fData(3145728)}
                                </Typography>
                            ) : null}
                        />
                        }

                        {
                            !isEdit
                            ?
                            <>
                                <RHFTextField disabled={!!isView} name="firstName" label="First Name" />
                                <RHFTextField disabled={!!isView} name="lastName" label="Last Name" />
                                <RHFTextField disabled={!!isView} name="displayName" label="Display Name" />
                                <RHFDatepicker disabled={!!isView} name="birthDate" label="Date of birth" />
                                <RHFTextField disabled={!!isView} name="nic" label="NIC" />
                                <RHFTextField disabled={!!isView} name="email" label="Email" />

                                <RHFSelect
                                    disabled={!!isView}
                                    fullWidth
                                    name="civilStatus"
                                    label="Civil Status"
                                    InputLabelProps={{ shrink: true }}
                                    PaperPropsSx={{ textTransform: 'capitalize' }}
                                >
                                    {civilStatusList.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </RHFSelect>
                            </>
                            :
                            <></>
                        }
                        
                        <RHFTextField disabled={!!isView} name="address" label="Address" />
                        <RHFTextField disabled={!!isView} name="mobileNumber" label="Mobile Number" />
                        <RHFTextField disabled={!!isView} name="whatsupNumber" label="Whatsapp  Number" />

                        {!isView && <RHFUploadAvatar
                            name="photoURL"
                            maxSize={3145728}
                            onDrop={handleDrop}
                            helperText={
                                <Typography
                                    variant="caption"
                                    sx={{
                                        mt: 3,
                                        mx: 'auto',
                                        display: 'block',
                                        textAlign: 'center',
                                        color: 'text.disabled',
                                    }}
                                >
                                    Allowed *.jpeg, *.jpg, *.png, *.gif
                                    <br /> max size of {fData(3145728)}
                                </Typography>
                            }
                        />}

                        <RHFSelect
                            disabled={!!isView}
                            fullWidth
                            name="district"
                            label="District"
                            InputLabelProps={{ shrink: true }}
                            PaperPropsSx={{ textTransform: 'capitalize' }}
                        >
                            {district.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </RHFSelect>
                    </Stack>
                </Card>
            </Grid>
        </>
    );

    const renderWorkInfo = (
        <>
            {mdUp && (
                <Grid md={4}>
                    <Typography variant="h6" sx={{ mb: 0.5 }}>
                        Work Info
                    </Typography>
                </Grid>
            )}

            <Grid xs={12} md={8}>
                <Card>
                    {!mdUp && <CardHeader title="Work Info" />}

                    <Stack spacing={3} sx={{ p: 3 }}>
                        <RHFSelect
                            fullWidth
                            name="employmentCategory"
                            label="Employment Category"
                            InputLabelProps={{ shrink: true }}
                            disabled={!!isView}
                            PaperPropsSx={{ textTransform: 'capitalize' }}
                        >
                            {employmentCategoryList.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </RHFSelect>

                        <RHFSelect
                            fullWidth
                            name="workType"
                            label="Work Type"
                            InputLabelProps={{ shrink: true }}
                            disabled={!!isView}
                            PaperPropsSx={{ textTransform: 'capitalize' }}
                        >
                            {workTypeList.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </RHFSelect>

                        <RHFSelect
                            fullWidth
                            name="role"
                            label="Role"
                            InputLabelProps={{ shrink: true }}
                            disabled={!!isView}
                            PaperPropsSx={{ textTransform: 'capitalize' }}
                        >
                            {designationList.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </RHFSelect>
                    </Stack>
                </Card>
            </Grid>
        </>
    );

    const renderEmergnecyContactInfo = (
        <>
            {mdUp && (
                <Grid md={4}>
                    <Typography variant="h6" sx={{ mb: 0.5 }}>
                        Emergency Contact
                    </Typography>
                </Grid>
            )}

            <Grid xs={12} md={8}>
                <Card>
                    {!mdUp && <CardHeader title="Emergency Contact" />}

                    <Stack spacing={3} sx={{ p: 3 }}>
                        <RHFTextField disabled={!!isView} name="emeName" label="Emergency contact person name" />
                        <RHFSelect
                            fullWidth
                            name="emeRelationship"
                            label="Emergency contact person relationship"
                            disabled={!!isView}
                            InputLabelProps={{ shrink: true }}
                            PaperPropsSx={{ textTransform: 'capitalize' }}
                        >
                            {relationShipList.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </RHFSelect>
                        <RHFTextField disabled={!!isView} name="emeNumber" label="Emergency contact person number" />
                    </Stack>
                </Card>
            </Grid>
        </>
    );

    const renderBankDetails = (
        <BankDetails isView={isView} />
    );

    const renderActions = (
        <>
            {mdUp && <Grid md={4} />}
            <Grid xs={12} md={8} sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <Box
                    sx={{ flexGrow: 1, pl: 3 }}
                />

                <LoadingButton type="submit" variant="contained" size="large" loading={inSubmission}>
                    {!staff ? 'Create' : 'Save Changes'}
                </LoadingButton>
            </Grid>
        </>
    );

    return (
        <Dialog
            fullWidth
            maxWidth={false}
            open={open}
            onClose={() => {
                reset();
                onClose();
            }}
            PaperProps={{
            sx: { maxWidth: 900 },
            }}
        >
            <DialogTitle>
                <Typography variant="h6" gutterBottom>
                    {
                        staff ? "Edit" : "Create"
                    }
                </Typography>
            </DialogTitle>

            <FormProvider methods={methods} onSubmit={onSubmit}>
                <Grid container spacing={3} sx={{px: 3}}>
                    {renderBasicInfo}

                    {
                        isEdit
                        ?
                        <>
                            {renderWorkInfo}
                            {renderBankDetails}
                            {/* {renderActions} */}
                        </>
                        :
                        <>
                            {!isView && renderWorkInfo}
                            {renderEmergnecyContactInfo}
                            {renderBankDetails}
                            {isView && renderWorkInfo}
                            {/* {!isView && renderActions} */}
                        </>
                    }
                </Grid>

                <DialogActions>
                    <Button variant="outlined" onClick={() => {
                        onClose();
                        reset();
                    }}
                    >
                        Close
                    </Button>

                    <LoadingButton type="submit" variant="contained" size="medium" loading={inSubmission}>
                        {!staff ? 'Create' : 'Update'}
                    </LoadingButton>
                </DialogActions>
            </FormProvider>
        </Dialog>
    );
}

StaffForm.propTypes = {
    staff: PropTypes.object,
    isView: PropTypes.bool,
    isEdit: PropTypes.bool,
    open: PropTypes.func, 
    onClose: PropTypes.func, 
    onSuccess: PropTypes.func
};
