import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useMemo, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { Card } from '@mui/material';
// routes
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'src/components/snackbar';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// _mock
// components
import { useGetAllPlaceNamesQuery } from 'src/services/places/places-api';
import { LoadingScreen } from 'src/components/loading-screen';
import { useAddTourPlanMutation, useGetAllInquiryRefQuery, useUpdateTourPlanMutation } from 'src/services/affordable/tour-plan/tour-plan-api';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import FormProvider from 'src/components/hook-form/form-provider';
import { isRefNo } from 'src/utils/custom-function';
import TourDayPlanDetails from './tour-day-plan-details';
import ErrorView from '../error/error-view';

// ----------------------------------------------------------------------

export default function TourPlanForm({ tourPlan, isView, refId = '' }) {

    const router = useRouter();

    const mdUp = useResponsive('up', 'md');

    const { enqueueSnackbar } = useSnackbar();

    const NewProductSchema = Yup.object().shape({
        tour_days: Yup.array().of(
            Yup.object().shape({
                to_place: Yup.object().shape({
                    id: Yup.string().required('To place cannot be empty'),
                }),
                from_place: Yup.object().shape({
                    id: Yup.string().required('From place cannot be empty'),
                }),
                hotel_name: Yup.string().required('Hotel name cannot be empty'),
                hotel_type: Yup.string().required('Hotel type cannot be empty'),
                day_no: Yup.number()
                    .typeError('Day must be a number')
                    .required('Day cannot be empty or invalid')
                    .min(1, 'Day cannot be less than 1'),
            })
        ),
    });

    const [updateTourPlan, { isSuccess: isSuccessUpdateTourPlan, error: errorUpdateTourPlan }] = useUpdateTourPlanMutation();
    const [createTourPlan, { isSuccess: isSuccessCreateTourPlan, error: errorCreateTourPlan }] = useAddTourPlanMutation();

    const { isLoading: isLoadingGetAllPlace, isSuccess: isSuccessGetAllPlace, data: getAllPlaceData, error: errorGetAllPlace } =
        useGetAllPlaceNamesQuery();

    const { isLoading: isLoadingGetAllInquiryRef, isSuccess: isSuccessGetAllInquiryRef, data: getAllInquiryRefData, error: errorGetAllInquiryRef } =
        useGetAllInquiryRefQuery();

    const defaultValues = useMemo(
        () => ({
            referenceId: tourPlan?.inquiry_ref || refId,
            tour_days: tourPlan?.tour_days || [],
        }),
        [refId, tourPlan]
    );

    const methods = useForm({
        resolver: yupResolver(NewProductSchema),
        defaultValues,
    });

    const {
        reset,
        handleSubmit,
        formState: { isSubmitting, },
    } = methods;

    const onSubmit = handleSubmit(async (data) => {
        if(data.tour_days.length === 0){
            enqueueSnackbar('Tour plan should consist minimum one tour day', { variant: 'error' });
            return;
        }

        try {
            const newTourDay = data.tour_days.map(day => {
                const fromPlace = getAllPlaceData.find(place => place.id === day.from_place.id);
                const toPlace = getAllPlaceData.find(place => place.id === day.to_place.id);

                return {
                    "day_no": day.day_no,
                    "date": null,
                    "from_place": day.from_place.id,
                    "from_place_name": fromPlace ? fromPlace.name : "",
                    "to_place": day.to_place.id,
                    "to_place_name": toPlace ? toPlace.name : "",
                    "hotel_name": day.hotel_name,
                    "hotel_type": day.hotel_type,
                    "activities": day.activities
                };
            });
            const body = {
                'tour_days': newTourDay,
                'inquiry_id': isRefNo(data.referenceId) ? null : data.referenceId,
                'inquiry_ref': isRefNo(data.referenceId) ? data.referenceId : null
            }
            if (tourPlan) {
                await updateTourPlan({ id: tourPlan.id, body });
            }
            else {
                await createTourPlan(body)
            }

        } catch (error) {
            console.error(error);
        }
    });

    useEffect(() => {
        if (tourPlan) {
            reset(defaultValues);
        }
    }, [tourPlan, defaultValues, reset]);

    useEffect(() => {
        if (isSuccessCreateTourPlan || isSuccessUpdateTourPlan) {
            enqueueSnackbar(isSuccessUpdateTourPlan ? 'Update success!' : 'Create success!');
            router.push(paths.dashboard.affordable.tourPlan);;
        }

        if (errorUpdateTourPlan || errorCreateTourPlan) {
            enqueueSnackbar(errorUpdateTourPlan ? errorUpdateTourPlan?.data?.message : errorCreateTourPlan?.data?.message, { variant: 'error' });
        }

    }, [enqueueSnackbar, errorCreateTourPlan, errorUpdateTourPlan, isSuccessCreateTourPlan, isSuccessUpdateTourPlan, router])

    useEffect(() => {
        const currentURL = window.location.href;
        const parts = currentURL.split("/");
        const value = parts[parts.length - 1];
        if (value === 'edit' || value === 'view') {
            if (!tourPlan) {
                router.push(paths.dashboard.affordable.tourPlan);
            }
        }
    }, [router, tourPlan]);

    const renderActions = (
        <>
            {mdUp && <Grid md={4} />}
            <Grid xs={12} md={8} m={2} sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <Box
                    sx={{ flexGrow: 1, pl: 3 }}
                />

                <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
                    {!tourPlan ? 'Create' : 'Update'}
                </LoadingButton>
            </Grid>
        </>
    );

    let placeContent;

    if (isLoadingGetAllPlace || isLoadingGetAllInquiryRef) {
        placeContent = <LoadingScreen />
    }
    else if (errorGetAllPlace) {
        placeContent = <>
            <ErrorView error={errorGetAllPlace?.data} showTryAgain={false}/>
        </>
    }
    else if (errorGetAllInquiryRef) {
        placeContent = <>
            <ErrorView error={errorGetAllInquiryRef?.data} showTryAgain={false}/>
        </>
    }
    else if (isSuccessGetAllPlace && isSuccessGetAllInquiryRef) {
        if (getAllPlaceData.length > 0) {
            placeContent = (
                <>
                    <TourDayPlanDetails placeData={getAllPlaceData} refData={getAllInquiryRefData} isView={isView} />
                    {isView ? null : renderActions}
                </>
            );
        }
        else {
            placeContent = <Typography variant="h6" sx={{ mb: 0.5 }}>
                Please Add Place
            </Typography>
        }
    }

    return (
        <FormProvider methods={methods} onSubmit={onSubmit}>
            <Card>
                {placeContent}
            </Card>

        </FormProvider>
    );
}

TourPlanForm.propTypes = {
    tourPlan: PropTypes.object,
    isView: PropTypes.bool,
    refId: PropTypes.any
};
