import PropTypes from 'prop-types';
// @mui
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider as MuiLocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  enUS as enUSAdapter,
} from 'date-fns/locale';


// ----------------------------------------------------------------------

export default function LocalizationProvider({ children }) {

  return (
    <MuiLocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enUSAdapter}>
      {children}
    </MuiLocalizationProvider>
  );
}

LocalizationProvider.propTypes = {
  children: PropTypes.node, 
};
