import { Button, Card, Container, Table, TableBody, TableContainer } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react'
import { useSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/custom-breadcrumbs';
import { LoadingScreen } from 'src/components/loading-screen';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import { TableNoData, TablePaginationCustom, useTable, TableHeadCustom } from 'src/components/table';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import { useAuthContext } from 'src/auth/hooks';
import { RoleBasedGuard } from 'src/auth/guard';
import { useDeleteStaffMutation, useGetAllStaffsMutation } from 'src/services/user/staff-api';
import { RouterLink } from 'src/routes/components';
import Iconify from 'src/components/iconify';
import { homeActions } from 'src/store/store';
import { useBoolean } from 'src/hooks/use-boolean';
import ErrorView from 'src/sections/error/error-view';
import StaffForm from '../staff-form';
import StaffTableRow from '../staff-table-row';
import StaffTableToolbar from '../staff-table-toolbar';
import StaffQuickView from '../staff-quick-view';

const TABLE_HEAD = [
    { id: 'name', label: 'Name' },
    { id: 'type', label: 'Email'},
    { id: 'address', label: 'Address'},
    { id: 'contact', label: 'Whatsapp'},
    { id: '', label: ''}
];

const defaultFilters = {
    name: '',
    ref: ''
};

export default function StaffView() {
    const settings = useSettingsContext();

    const { enqueueSnackbar } = useSnackbar();

    const router = useRouter();

    const dispatch = useDispatch();

    const { isUserRoleHasAccess } = useAuthContext();

    const table = useTable({ defaultOrderBy: 'name' });

    const [tableData, setTableData] = useState([]);
    const [totalElements, setTotalElements] = useState(0);

    const [filters, setFilters] = useState(defaultFilters);
    const [searchTimer, setSearchTimer] = useState(null);
    const [searchKey, setSearchKey] = useState(null);
    const [isShow, setIsShow] = useState(false);

    const [getAllStaffs, { isLoading, isSuccess, data, error }] = useGetAllStaffsMutation();

    const [deleteStaff, { error: errorDeleteStaff, isSuccess: isSuccessDeleteStaff }] = useDeleteStaffMutation();

    const canReset =
        !!filters.name ||
        !!filters.ref;

    const notFound = (!tableData.length && canReset) || !tableData.length;

    const quickView = useBoolean();
    const quickEdit = useBoolean();
    const quickCreate = useBoolean();
    const [viewData, setViewData] = useState(null);

    const handleResetFilters = useCallback(() => {
        setSearchKey(null);
        setIsShow(false);
        setFilters(defaultFilters);
    }, []);

    const handleFilters = useCallback((name, value) => {
        table.onResetPage();

        setSearchKey(value);

        if (searchTimer) {
            clearTimeout(searchTimer);
        }

        if (!value) {
            handleResetFilters();
        }

        const newSearchTimer = setTimeout(() => {
            getAllStaffs({ page: table.page, size: table.rowsPerPage, key: value });
        }, 1000);

        setSearchTimer(newSearchTimer);
    },[getAllStaffs, handleResetFilters, searchTimer, table]);

    useEffect(() => {
        getAllStaffs({ page: table.page, size: table.rowsPerPage, key: searchKey });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [table.page, table.rowsPerPage]);

    const handleDeleteRow = useCallback(
        (id) => {
            deleteStaff(id);
        },
        [deleteStaff]
    );

    const handleViewRow = useCallback(
        (row) => {
            dispatch(
                homeActions.setStaffData(row)
            );
            router.push(paths.dashboard.user.viewStaff(row.id));
        },
        [dispatch, router]
    );

    const handleQuickViewRow = useCallback((row) => {
        dispatch(
            homeActions.setStaffData(row)
        );

        quickView.onTrue();
    },[dispatch, quickView]);

    // const handleEditRow = useCallback(
    //     (row) => {
    //         dispatch(
    //             homeActions.setStaffData(row)
    //         );
    //         router.push(paths.dashboard.user.editStaff(row.id));
    //     },
    //     [dispatch, router]
    // );

    const handleEditRow = useCallback((row) => {
        if(!quickEdit.value){
            setViewData(row);
            quickEdit.onTrue();
        }
    },[quickEdit]);

    const handleCreateView = useCallback(() => {
        quickCreate.onTrue();
    },[quickCreate]);

    useEffect(() => {
        if (isSuccess) {
            setTableData(data.content)
            setTotalElements(data.totalElements);
        }
    }, [isSuccess, data]);

    useEffect(() => {
        if (errorDeleteStaff) {
            enqueueSnackbar('Deletion Failed!', { variant: 'error' });
        }

        if (isSuccessDeleteStaff) {
            enqueueSnackbar('Succesfully deleted the staff');
            editRefreshCall();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enqueueSnackbar, errorDeleteStaff, isSuccessDeleteStaff]);

    const editRefreshCall = useCallback(() => {
        getAllStaffs({ page: table.page, size: table.rowsPerPage, key: searchKey });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchKey, table.page, table.rowsPerPage]);

    let staffContent;

    if (isLoading) {
        staffContent = <LoadingScreen />
    }
    else if (error) {
        staffContent = <>
            <ErrorView error={error?.data} showTryAgain={false}/>
        </>
    }
    else if (isSuccess) {
        staffContent = (
            <Card>
                <StaffTableToolbar
                    value={searchKey}
                    onFilters={handleFilters}
                />
                
                <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                    <Scrollbar>
                        <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 800 }} stickyHeader>
                            <TableHeadCustom
                                order={table.order}
                                orderBy={table.orderBy}
                                headLabel={TABLE_HEAD}
                                rowCount={tableData.length}
                                numSelected={table.selected.length}
                                onSort={table.onSort}
                                onSelectAllRows={(checked) =>
                                table.onSelectAllRows(
                                checked,
                                tableData.map((row) => row.id)
                                )}
                            />

                            <TableBody>
                                {tableData
                                    .map((row) => (
                                        <StaffTableRow
                                            key={row.id}
                                            row={row}
                                            onViewRow={() => handleQuickViewRow(row)}
                                            onEditRow={() => handleEditRow(row)}
                                            onDeleteRow={() => handleDeleteRow(row.id)}
                                        />
                                    ))}
                                <TableNoData notFound={notFound} />
                            </TableBody>
                        </Table>
                    </Scrollbar>
                </TableContainer>

                <TablePaginationCustom
                    count={totalElements}
                    page={table.page}
                    rowsPerPage={table.rowsPerPage}
                    onPageChange={table.onChangePage}
                    onRowsPerPageChange={table.onChangeRowsPerPage}
                    dense={table.dense}
                    onChangeDense={table.onChangeDense}
                />
            </Card>
        );
    }

    return (
        <Container maxWidth={settings.themeStretch ? false : 'lg'}>
            <CustomBreadcrumbs
                heading="Staff"
                links={[
                    {
                        name: 'Dashboard',
                        href: paths.dashboard.driverReview.root,
                    },
                    {
                        name: 'Staff',
                        href: paths.dashboard.payment.paymentCollector,
                    },
                ]}
                action={
                    isUserRoleHasAccess(7, 0)
                    ?
                    <Button
                        component={RouterLink}
                        // href={paths.dashboard.user.createStaff}
                        variant="contained"
                        startIcon={<Iconify icon="mingcute:add-line" />}
                        onClick={handleCreateView}
                    >
                        Add Staff
                    </Button>
                    :
                    <></>
                }
                sx={{
                    mb: { xs: 3, md: 5 },
                }}
            />

            <RoleBasedGuard hasContent mainMenuOrder={7} subMenuOrder={0} sx={{ py: 10 }}>
                {staffContent}

                <StaffQuickView open={quickView.value} onClose={quickView.onFalse} />
                <StaffForm open={quickCreate.value} onClose={quickCreate.onFalse} onSuccess={editRefreshCall}/>
                <StaffForm staff={viewData} open={quickEdit.value} onClose={quickEdit.onFalse} onSuccess={editRefreshCall} isEdit/>
            </RoleBasedGuard>
        </Container>
    )
}