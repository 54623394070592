// @mui
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { LoadingScreen } from 'src/components/loading-screen';
import { useSearchParams } from 'src/routes/hooks';
import { useGetPaymentByIDQuery } from 'src/services/payment/payment-api';
import ErrorView from 'src/sections/error/error-view';
import PaymentViewForm from '../payment-view-form';


// ----------------------------------------------------------------------

export default function ViewPayment() {
    const settings = useSettingsContext();

    const searchParams = useSearchParams();

    const id = searchParams.get('id');

    const  { isLoading, isSuccess, data, error } =useGetPaymentByIDQuery(id);

    let paymentContent;
    if (isLoading) {
        paymentContent = <LoadingScreen />
    }
    else if (error) {
        paymentContent = <>
            <ErrorView error={error?.data} showTryAgain={false}/>
        </>
    }
    else if (isSuccess) {
        paymentContent = <PaymentViewForm payment={data} />
    }

    return (
        <Container maxWidth={settings.themeStretch ? false : 'lg'}>
            <CustomBreadcrumbs
                heading="View Payment"
                links={[
                    {
                        name: 'Dashboard',
                        href: paths.dashboard.driverReview.root,
                    },
                    {
                        name: 'Driver Guide',
                        href: paths.dashboard.payment.root,
                    },
                    {
                        name: "View Payment"
                    },
                ]}

                sx={{
                    mb: { xs: 3, md: 5 },
                }}
            />
            {paymentContent}
        </Container>
    );
}
